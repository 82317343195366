<template>
    <section class="main-visual">
        <div class="container"> 
            <div class="main-visual-content">
                <img class="main-visual-content-logo" src="@/assets/img/main/dhfesta_logo_eng_1.png" alt="동행축제">
                <img class="main-visual-content-model" src="@/assets/img/main/model_image-1.png" alt="사람들">
            </div>
        </div>
        <div class="main-visual-wave">
            <img src="@/assets/img/main/main_wave.png" alt="" />
        </div>
    </section>
</template>
<script>
export default {
    name: 'EngMainLivePr',
	components: {},
	data: () => ({}),
	computed: {},
	created() {},
	mounted() {},
	methods: {},
}
</script>