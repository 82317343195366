<template>
	<section id="engMainInfo" class="msection">
		<div class="container">
			<div class="fp-content">
				<div class="msection-title">
					<span class="title-prima">Joyful Shopping Experience</span>
					<span class="title-large">2024 K-SHOPPING FESTA</span>
				</div>
				<p class="msection-text">To boost domestic demand, a large-scale national consumption festival, will be held to ease the burden of consumption and increase sales of small and medium-sized businesses</p>
			</div>

			<div class="fp-content eng">
				<div class="event-informs">
					<div class="event-info">
						<div class="event-info-icon">
							<img src="../../assets/img/main/event_icon01.png" alt="행사기간" />
						</div>
						<div class="event-info-desc">
							<span class="desc-title">Period</span>
							<div class="desc-date">
								<span>2024.8.28(Wed) ~ 9.28(Sat), 32days</span>
							</div>
						</div>
					</div>
					<div class="event-info">
						<div class="event-info-icon">
							<img src="../../assets/img/main/event_icon02.png" alt="행사내용" />
						</div>
						<div class="event-info-desc">
							<span class="desc-title">Details</span>
							<p class="desc-detail">Online special events, livestream shopping, K-SHOPPING product sales events, sales events linked to local festivals, and Events to boost spending</p>
						</div>
					</div>
				</div>
			</div>
			<div class="event-youtube-container">
				<div class="youtube-wrap"><iframe src="https://www.youtube.com/embed/uo_KZYwhh3I?si=Rl9N0LlC5vDSw_jx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen=""></iframe></div>
			</div>
			<div class="event-imglist-container">
				<swiper ref="swiper" class="swiper" :options="swiperOption" @slideChange="onSwiperChange">
					<swiper-slide v-for="(item, idx) in festaList" :key="idx" class="info-imgslide">
						<div class="content-img">
							<img :src="require(`@/assets/img/main/${item.image}`)" alt="" />
						</div>
					</swiper-slide>
				</swiper>
			</div>
		</div>
	</section>
</template>
<script>
import Icon from '@/components/common/Icon';

export default {
	name: 'EngMainInfoVideo',
	components: { Icon },
	data: () => ({
		thisSwiper: null,
		activeIndex: 0,
		swiperOption: {
			loop: false,
			slidesPerView: 3,
			spaceBetween: 24,
			autoplay: {
				delay: 3000,
				disableOnInteraction: true,
			},
			navigation: {
				nextEl: '#engMainInfo .swiper-button-next',
				prevEl: '#engMainInfo .swiper-button-prev',
			},
			pagination: {
				el: '#engMainInfo .swiper-pagination',
				type: 'fraction',
			},
			breakpoints: {
				200: {
					slidesPerView: 1.5,
				},
				680: {
					slidesPerView: 2.5,
				},
				800: {
					slidesPerView: 3,
				},
			},
		},
		festaList: [
			{
				image: 'bg_festa7.jpg',
			},
			{
				image: 'bg_festa2.jpg',
			},
			{
				image: 'bg_festa3.jpg',
			},
		],
	}),
	computed: {},
	created() {},
	beforeDestroy() {},
	mounted() {
		this.$nextTick(() => {
			this.thisSwiper = this.$refs.swiper.$swiper;
		});
	},
	methods: {
		onSwiperChange() {
			this.activeIndex = this.thisSwiper.realIndex;
		},
	},
};
</script>
