<template>
	<section id="engMainRealtime" class="msection">
		<div class="container">
			<div class="msection-header">
				<div class="flex-left">
					<h2 class="msection-title">Featured Products</h2>
					<span class="msection-subtitle">Featured Products You Can’t Miss</span>
				</div>
				<div class="flex-right">
					<router-link to="/en/products" class="link-more">
						<span class="text">View More</span>
						<i class="icon"><icon name="icon-arrow-r" /></i>
					</router-link>
				</div>
			</div>

			<div class="msection-content" id="realtimeDibs">
				<swiper ref="swiper" class="swiper" :options="swiperOption" @slideChange="onSwiperChange">
					<swiper-slide v-for="(brcItem, idx) in manprdList" :key="idx" class="realtimeslide">
						<a href="javascript:;" @click.prevent="handleClickModal(brcItem.id)" class="realtime-item">
							<div class="realtime-content">
								<div class="content-img">
									<img :src="brcItem.image" :alt="brcItem.title" />
								</div>
								<div class="content-info">
									<span class="info-brand">{{ brcItem.company }}</span>
									<span class="info-title">{{ brcItem.title }}</span>
									<div class="info-footer">
										<span class="info-price">{{ Number(brcItem.price).toLocaleString() }}</span>
									</div>
								</div>
							</div>
						</a>
					</swiper-slide>
				</swiper>
				<swiper-controls :swiper="thisSwiper" :auto="true"></swiper-controls>
			</div>
			<products-modal :visible="visibleModal" @closeModal="visibleModal = false" :item="modalItem" :like="false" :rate="false" parent-click-evnt-nm="clickEvnt" @clickEvnt="handleClickModalEvnt" />
		</div>
	</section>
</template>
<script>
import Icon from '@/components/common/Icon';
import SwiperControls from '@/components/common/SwiperControls';
import { ACT_GET_CAMPN_MANPRD_RAND_LIST, ACT_UPDATE_CAMPN_MANPRD_FAVR } from '@/store/_act_consts';
import { mapGetters } from 'vuex';
import { getItems, lengthCheck, getCheckItems, setPaging, tryResCallback, queryToValue, isSuccess, dateToDateFormat, timestampToDateFormat } from '@/assets/js/utils';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_CONFIRM, MUT_SHOW_ALERT } from '@/store/_mut_consts';
import { countDDay } from '@/assets/js/DateUtils';
import ProductsModal from '@/components/content/ProductsModal';

export default {
	name: 'EngMainRealtimeDibs',
	components: { Icon, SwiperControls, ProductsModal },
	data: () => ({
		thisSwiper: null,
		activeIndex: 0,
		modalId: 0,
		manprdList: [],
		visibleModal: false,
		swiperOption: {
			loop: false,
			slidesPerView: 4,
			spaceBetween: 20,
			autoplay: {
				delay: 3000,
				disableOnInteraction: true,
			},
			navigation: {
				nextEl: '#realtimeDibs .swiper-button-next',
				prevEl: '#realtimeDibs .swiper-button-prev',
			},
			pagination: {
				el: '#realtimeDibs  .swiper-pagination',
				type: 'fraction',
			},
			breakpoints: {
				200: {
					slidesPerView: 1.5,
				},
				680: {
					slidesPerView: 2.5,
				},
				800: {
					slidesPerView: 3,
				},
				1150: {
					slidesPerView: 4,
				},
			},
		},
	}),
	computed: {
		...mapGetters('common', ['isMobile', 'commonCode', 'campn']),
		...mapGetters('auth', ['session', 'isAuth']),
		campnId() {
			return this.campn.campnId;
		},
		modalItem() {
			if (this.modalId > 0) return this.manprdList.find((i) => i.id == this.modalId);

			return null;
		},
	},
	created() {
		this.getManprdList();
	},
	mounted() {
		this.$nextTick(() => {
			this.thisSwiper = this.$refs.swiper.$swiper;
		});
	},
	methods: {
		onSwiperChange() {
			this.activeIndex = this.thisSwiper.realIndex;
		},
		clickLike(idx) {
			this.manprdList[idx].like = !this.manprdList[idx].like;
		},
		//  F: 찜순, S: 별점순
		getManprdList() {
			this.manprdList = [];

			// api 호출
			this.$store
				.dispatch(`manprd/${ACT_GET_CAMPN_MANPRD_RAND_LIST}`, {
					campnId: this.campnId,
					randCnt: 10,
				})
				.then((res) => {
					if (lengthCheck(res)) {
						this.manprdList = getCheckItems(res).map((i) => {
							return {
								id: i.manprdId,
								image: i.manprdImgFileUrl,
								company: i.brndEngNm,
								title: i.manprdEngNm,
								price: i.manprdPrc,
								rate: i.socreAvg,
								rateCount: i.scoreCnt,
								like: i.favrYn == 'Y',
								likeCount: i.favrCnt,
								myRate: i.myScore,
								desc: i.manprdEngDesc,
								siteUrl: i.ntslSiteUrl,
								isEng : true,
								useCampnDurYn: i.useCampnDurYn == 'Y',
								ntslStrtDt: timestampToDateFormat(i.ntslStrtDt, 'yyyy-MM-dd'),
								ntslEndDt: timestampToDateFormat(i.ntslEndDt, 'yyyy-MM-dd')
							};
						});
					}
				})
				.catch((e) => {
					console.error(e);
				});
		},
		chkLogin(title) {
			if (typeof this.session.userId == 'undefined') {
				const param = {
					title: title,
					html: true,
					showCancelButton: false,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
					nofunc: () => {},
				};
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
				return false;
			}

			return true;
		},
		chkCampnDuration() {

			const nowDt = dateToDateFormat(new Date(), 'yyyy-MM-dd') + ' 00:00';
			// 기간 체크
			if (countDDay(this.campn.strtDt, nowDt) < 0 || countDDay(nowDt, this.campn.endDt) < 0) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: `이벤트 기간이 아닙니다.`,
					rightNow: true,
				});
				return false;
			}
			return true;
		},
		handleClickModal(idx, type) {
			this.modalId = idx;
			this.visibleModal = true;
		},
		handleClickModalEvnt(type, score) {
			this.getManprdList();
		},
	},
};
</script>
