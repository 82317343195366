<template>
	<main v-if="visible" class="layer-popup-container">
		<div class="layer-popup" id="layerPopupNews">
			<div>
				<div class="popup-wrapper">
					<header class="popup-header">
						<h2 class="header-title">{{ item.title }}</h2>
						<button type="button" class="btn-close" @click="clickClose">
							<i class="icon-close">팝업닫기</i>
						</button>
					</header>
					<div class="popup-body" data-fill="true">
						<div class="content" v-html="item.content"></div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import Icon from '@/components/common/Icon';
export default {
	name: 'EngMainNewsModal',
	props: {
		visible: Boolean,
		item: Object,
	},
	components: { Icon },
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		clickClose() {
			this.$emit('closeModal');
		},
	},
};
</script>
