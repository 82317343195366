<template>
	<main id="container">
		<!-- 메인 비주얼 -->
		<eng-main-visual />

		<div class="msection-wrap">
			<!-- 황금녘 동행축제 안내 및 영상  -->
			<eng-main-info-video />

			<!-- 동행축제 실시간 찜하기 -->
			<eng-main-realtime-dibs />

			<!-- 동행뉴스 -->
			<!-- <eng-main-news /> -->
		</div>
	</main>
</template>
<script>
import { mapGetters } from 'vuex';
import EngMainVisual from '@/components/main/EngMainVisual';
import EngMainInfoVideo from '@/components/main/EngMainInfoVideo';
import EngMainRealtimeDibs from '@/components/main/EngMainRealtimeDibs';
import EngMainNews from '@/components/main/EngMainNews';

export default {
	name: 'Main',
	components: { EngMainVisual, EngMainInfoVideo, EngMainRealtimeDibs, EngMainNews },
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile', 'returnRoute']),
	},
	watch: {},
	props: {},
	data: () => ({}),
	created() {},
	beforeDestroy() {},
	mounted() {},
	methods: {},
	destroyed() {},
};
</script>
