<template>
	<section id="engMainNews" class="msection">
		<div class="container">
			<div class="msection-header">
				<div class="flex-left">
					<h2 class="msection-title">K-Shopping News</h2>
				</div>
			</div>
			<div class="msection-content">
				<div class="main-news-container">
					<swiper ref="swiper" class="swiper" :options="swiperOption" @slideChange="onSwiperChange">
						<swiper-slide v-for="(item, idx) in itemList" :key="idx" class="main-news-slide">
							<button type="button" class="content-list-item" @click="handleClickModal(idx)">
								<div class="content-list-desc">
									<div class="content-list-desc-img"><img :src="require(`@/assets/img/main/${item.image}`)" alt="" /></div>
									<div class="content-list-desc-text">
										<span class="content-list-desc-title">{{ item.title }}</span>
									</div>
								</div>
							</button>
						</swiper-slide>
					</swiper>
					<div class="swiper-buttons-container">
						<div class="swiper-button-prev" slot="button-prev">
							<i class="icon"><icon name="icon-swiper-arrow"></icon></i>
						</div>
						<div class="swiper-button-next" slot="button-next">
							<i class="icon"><icon name="icon-swiper-arrow"></icon></i>
						</div>
					</div>
				</div>
			</div>
		</div>
		<eng-main-news-modal :visible="visibleModal" @closeModal="visibleModal = false" :item="modalItem" />
	</section>
</template>
<script>
import Icon from '@/components/common/Icon';
import EngMainNewsModal from './EngMainNewsModal';
export default {
	name: 'EngMainNews',
	components: { Icon, EngMainNewsModal },
	data: () => ({
		visibleModal: false,
		modalItem: {},
		thisSwiper: null,
		activeIndex: 0,
		swiperOption: {
			loop: false,
			slidesPerView: 3,
			spaceBetween: 24,
			navigation: {
				nextEl: '#engMainNews .swiper-button-next',
				prevEl: '#engMainNews .swiper-button-prev',
			},
			pagination: {
				el: '#engMainNews .swiper-pagination',
				type: 'fraction',
			},
			breakpoints: {
				200: {
					spaceBetween: 16,
					slidesPerView: 1.5,
				},
				680: {
					spaceBetween: 24,
					slidesPerView: 2.5,
				},
				800: {
					spaceBetween: 24,
					slidesPerView: 3,
				},
			},
		},
		itemList: [
			{
				image: 'bg_festa1.jpg',
				title: 'Today news is Today news is Today news is Today news is Today news is Today news is Today news is Today news is Today news is ',
				content: '<h3>K-Shopping Festa</h3> <p>Featured Products Up to 50% off</p> <img src="https://k-shoppingfesta.org/data/file/news_en/df6dceaee31aa2c08727fc278b2f4f1e_yXor9m1i_91e8522a76f77aca3b71489c2d851d1873f7436b.png"><br><img src="https://k-shoppingfesta.org/data/file/news_en/df6dceaee31aa2c08727fc278b2f4f1e_PGolFOzM_50012e99a2a38fb9be4b34d44342bd6941d631d5.png">',
			},
			{
				image: 'bg_festa2.jpg',
				title: 'Today news is Today news is Today news is Today news is Today news is Today news is Today news is Today news is Today news is ',
				content: 'content2',
			},
			{
				image: 'bg_festa2.jpg',
				title: 'Today news is Today news is Today news is Today news is Today news is Today news is Today news is Today news is Today news is ',
				content: 'content3',
			},
			{
				image: 'bg_festa2.jpg',
				title: 'Today news is Today news is Today news is Today news is Today news is Today news is Today news is Today news is Today news is ',
				content: 'content4',
			},
		],
	}),
	computed: {},
	created() {},
	beforeDestroy() {},
	mounted() {
		this.$nextTick(() => {
			this.thisSwiper = this.$refs.swiper.$swiper;
		});
	},
	methods: {
		onSwiperChange() {
			this.activeIndex = this.thisSwiper.realIndex;
		},
		handleClickModal(idx) {
			this.visibleModal = true;
			this.modalItem = this.itemList[idx];
		},
	},
};
</script>
